export class ProductSettings {
    enableContactUsLink: number;
    enableDownloadStatementsSection: number;
    enableMaintenanceInvoicesSection: number;
    enableMaintenanceSection: number;
    enableMaintenanceNotesColumn: number;
    enableMaintenanceNotesSection: number;
    enableArrearsNotesSection: number;
    enableOurWebsiteLink: number;
    enableProfitAndLossSection: number;
    enableRentOutstandingSection: number;
    enableSelfAssessmentTaxSection: number;
    enableAddPropertyListing: number;
    enableCertificatesSection: number;
    enableMaintenancePreferencesSection: number;
    enableInventorySection: number;
    enableTenantsSection: number;
    enablePaymentHistorySection: number;
    enableEditPropertyListing: number;
    displayCompanyPhoneNumber: number;
    loginSlides: string[];
    demoUsername?: string;
    demoPassword?: string;

    constructor(params) {
        if (!params) {
            params = [];
        }
        this.enableContactUsLink = params['enableContactUsLink'] === 0 ? 0 : 1;
        this.enableDownloadStatementsSection = params['enableDownloadStatementsSection'] === 0 ? 0 : 1;
        this.enableMaintenanceInvoicesSection = params['enableMaintenanceInvoicesSection'] === 0 ? 0 : 1;
        this.enableMaintenanceSection = params['enableMaintenanceSection'] === 0 ? 0 : 1;
        this.enableMaintenanceNotesColumn = params['enableMaintenanceNotesColumn'] === 0 ? 0 : 1;
        this.enableArrearsNotesSection = params['enableArrearsNotesSection'] === 0 ? 0 : 1;
        this.enableOurWebsiteLink = params['enableOurWebsiteLink'] === 0 ? 0 : 1;
        this.enableProfitAndLossSection = params['enableProfitAndLossSection'] === 0 ? 0 : 1;
        this.enableRentOutstandingSection = params['enableRentOutstandingSection'] === 0 ? 0 : 1;
        this.enableSelfAssessmentTaxSection = params['enableSelfAssessmentTaxSection'] === 0 ? 0 : 1;
        this.enableAddPropertyListing = params['enableAddPropertyListing'] === 0 ? 0 : 1;
        this.enableEditPropertyListing = params['enableAddPropertyListing'] === 0 ? 0 : 1;
        this.enableCertificatesSection = params['enableCertificatesSection'] === 0 ? 0 : 1;
        this.enableMaintenancePreferencesSection = params['enableMaintenancePreferencesSection'] === 0 ? 0 : 1;
        this.enableInventorySection = params['enableInventorySection'] === 0 ? 0 : 1;
        this.enableTenantsSection = params['enableTenantsSection'] === 0 ? 0 : 1;
        this.enablePaymentHistorySection = params['enablePaymentHistorySection'] === 0 ? 0 : 1;
        this.enableMaintenanceNotesSection = params['enableMaintenanceNotesSection'] === 0 ? 0 : 1;
        this.demoUsername = params['demoUsername'] || '';
        this.demoPassword = params['demoPassword'] || '';

        this.loginSlides = params['loginSlides'] || [];
    }
}
