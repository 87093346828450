import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {CFG, AuthService, AuthIdleService, UtilService, SettingsService, ProductSettings} from '../../core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    logoSrc: string;
    CFG = CFG;
    websiteUrl;

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authSvc: AuthService,
        private authIdleSvc: AuthIdleService,
        private utilService: UtilService,
        private settingsService: SettingsService
    ) { }
    ngOnInit() {
        this.logoSrc = `${environment.apiUrl}settings/photo/logo`;
        const productSettings: ProductSettings = (this.settingsService.get()).productSettings;
        this.websiteUrl = this.settingsService.getWebsiteUrl();

        this.form = this.fb.group({
            username: [productSettings.demoUsername || '', { validators: [Validators.required]}],
            password: [productSettings.demoPassword || '', Validators.required]
        });
    }

    onSubmit(): void {
        if (!this.form.valid) {
            this.utilService.markFormGroupDirty(this.form);
            return;
        }
        // Disable the form
        this.form.disable();

        // Call login request
        this.authSvc.login(this.form.value).subscribe(
            data => {
                // Start auth-idle service
                this.authIdleSvc.start();

                this.goContinue();
            },
            () => {
                // Enable the form
                this.form.enable();
                this.form.setErrors({ incorrect: true });
            }
        );
    }

    private goContinue() {
        // Get redirect url
        const redirectUrl: any = this.authSvc.redirectUrl;
        // Clear redirect url
        this.authSvc.redirectUrl = '';

        // Redirect to the url
        if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
        } else {
            // Redirect to the main route by the user role.
            // this.appSvc.navigateToMainRoute();
            this.router.navigate(['/dashboard']);
        }
    }

    get username() {
        return this.form.get('username');
    }

    get password() {
        return this.form.get('password');
    }
}
