export class AppSettings {
    loggedIn: boolean;
    year: string;
    needSocial: boolean;

    constructor(params) {
        if (!params) {
            params = [];
        }
        this.loggedIn = params['loggedIn'] || false;
        this.year = params['year'];
        this.needSocial = params['needSocial'] || false;
    }
}
