import { ProductSettings } from './product-settings.model';
import { AppSettings } from './app-settings.model';

export class Settings {
    id: number;
    logo: string;
    name: string;
    websiteUrl: string;

    color1: string;
    color2: string;
    color3: string;
    color4: string;
    color5: string;

    favicon: string;
    googlePlusUrl: string;
    linkedInUrl: string;
    twitterUrl: string;
    facebookUrl: string;

    telephoneLettings: string;
    telephoneSales: string;
    emailLettings: string;
    emailSales: string;

    vatNumber: number;
    updated: string;

    productSettings: ProductSettings;
    app: AppSettings;

    constructor(params) {
        if (!params) {
            params = [];
        }
        this.id = params['id'] || 0;
        this.logo = params['logo'] || '';
        this.name = params['name'] || '';
        this.websiteUrl = params['websiteUrl'] || '';

        this.color1 = params['color1'] || '';
        this.color2 = params['color2'] || '';
        this.color3 = params['color3'] || '';
        this.color4 = params['color4'] || '';
        this.color5 = params['color5'] || '';

        this.favicon = params['favicon'] || '';
        this.googlePlusUrl = params['googlePlusUrl'] || '';
        this.linkedInUrl = params['linkedInUrl'] || '';
        this.twitterUrl = params['twitterUrl'] || '';
        this.facebookUrl = params['facebookUrl'] || '';

        this.telephoneLettings = params['telephoneLettings'] || '';
        this.telephoneSales = params['telephoneSales'] || '';
        this.emailLettings = params['emailLettings'] || '';
        this.emailSales = params['emailSales'] || '';

        this.vatNumber = params['vatNumber'] || 0;
        this.updated = params['updated'] || '';

        this.productSettings = new ProductSettings(params['productSettings']);
        this.app = new AppSettings(params['app']);

    }

}
