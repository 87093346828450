import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from './services';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && UtilService.isNumber(dateParts[0])) {
                return { day: UtilService.toInteger(dateParts[0]), month: null, year: null };
            } else if (dateParts.length === 2 && UtilService.isNumber(dateParts[0]) && UtilService.isNumber(dateParts[1])) {
                return { day: UtilService.toInteger(dateParts[0]), month: UtilService.toInteger(dateParts[1]), year: null };
            // tslint:disable-next-line:max-line-length
            } else if (dateParts.length === 3 && UtilService.isNumber(dateParts[0]) && UtilService.isNumber(dateParts[1]) && UtilService.isNumber(dateParts[2])) {
                // tslint:disable-next-line:max-line-length
                return { day: UtilService.toInteger(dateParts[0]), month: UtilService.toInteger(dateParts[1]), year: UtilService.toInteger(dateParts[2]) };
            }
        }
        return null;
    }

    format(date: NgbDateStruct): string {
        return date ?
            // tslint:disable-next-line:max-line-length
            `${UtilService.isNumber(date.day) ? UtilService.padNumber(date.day) : ''}/${UtilService.isNumber(date.month) ? UtilService.padNumber(date.month) : ''}/${date.year}` :
            '';
    }
}
