import { Component } from '@angular/core';
import { HTTPStatus } from './httpInterceptor';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent {
    HTTPActivity: boolean;

    constructor(private httpStatus: HTTPStatus) {

        this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
            this.HTTPActivity = status;
        });
    }
}
