import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
    private error: string;

    constructor() {
    }

    get() {
        return this.error;
    }

    set(error: string) {
        this.error = error;
    }
}
