import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuardService } from './core';
import { AccountComponent } from './account/account.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ArrearsComponent } from './arrears/arrears.component';
import { PropertyComponent } from './property/property.component';
import { DashboardResolver } from './dashboard/dashoard-resolver.service';
import { AddPropertyComponent } from './add-property/add-property.component';
import { EditPropertyComponent } from './edit-property/edit-property.component';
import { AddPropertyGuardService } from './core/services/add-property.guard';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                resolve: [DashboardResolver],
                data: {
                    title: 'Dashboard'
                }
            },
            {
                path: 'property/:id',
                component: PropertyComponent,
                resolve: [DashboardResolver],
                data: {
                    title: 'Property Details'
                }
            },
            {
                path: 'edit/:id',
                component: EditPropertyComponent,
                data: {
                    title: 'Edit property'
                }
            },
            {
                path: 'account',
                component: AccountComponent,
                data: {
                    title: 'Account'
                }
            },
            {
                path: 'arrears',
                component: ArrearsComponent,
                data: {
                    title: 'Arrears'
                }
            },
            {
                path: 'maintenance',
                component: MaintenanceComponent,
                data: {
                    title: 'Maintenance'
                }
            },
            {
                path: 'add-property',
                component: AddPropertyComponent,
                canActivate: [AddPropertyGuardService],
                data: {
                    title: 'Add Property Listing'
                }
            },
            {
                path: 'error',
                component: ErrorComponent,
                data: {
                    title: 'Error'
                }
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/error'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
