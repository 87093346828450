import { ColumnSetting } from '../core/shared/table-layout/layout.model';
import { PaginationSettings } from '../core/shared/table-layout/table.model';

export const certificatesTable: ColumnSetting[] = [
    {
        primaryKey: 'Type',
        header: 'Type',
    },
    {
        primaryKey: 'Due',
        header: 'Due'
    },
    {
        primaryKey: 'url',
        templateDlBtn: true,
    }
];
export const certificatesPagination: PaginationSettings = {
    id: 'p1',
    itemsPerPage: 99,
    maxSize: 9
};

export const preferencesTable: ColumnSetting[] = [
    {
        primaryKey: 'Notes',
    }
];
export const preferencesPagination: PaginationSettings = {
    id: 'p2',
    itemsPerPage: 99,
    maxSize: 9
};
