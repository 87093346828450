import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Pipe(
    { name: 'formatCell' }
)
export class FormatCellPipe implements PipeTransform {
    constructor(
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe
    ) { }
    transform(value: any, format: string) {
        if (format === 'date') {
            return this.datePipe.transform(value, 'dd/MM/yy');
        }

        if (format === 'currency') {
            return this.currencyPipe.transform(value, '£', true);
        }

        return value;
    }
}
