import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SideNavService {
    lastState = false;

    // Observable string stream.
    state$: Subject<boolean> = new Subject();

    open() {
        this.state$.next(true);
    }

    close() {
        this.state$.next(false);
    }
}
