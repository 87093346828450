import { NgModule } from '@angular/core';
import { FormatCellPipe } from './format-cell.pipe';
import { DatePipe, CurrencyPipe } from '@angular/common';

@NgModule({
    declarations: [
        FormatCellPipe
    ],
    exports: [
        FormatCellPipe
    ],
    providers: [
        DatePipe,
        CurrencyPipe
    ],
})
export class FormatCellPipeModule { }
