import { Directive, OnInit, ElementRef, Input, Inject } from '@angular/core';
import { ApiService } from '../services';
import { WINDOW } from '../window-factory';

@Directive({
    selector: '[appApiSrc]'
})
export class ApiSrcDirective implements OnInit {
    @Input('appApiSrc') apiSrc: string;
    @Input() aHref;
    objectURL;

    constructor(
        private $el: ElementRef,
        private apiService: ApiService,
        @Inject(WINDOW) private window: Window
    ) { }


    ngOnInit() {
        if (this.apiSrc.indexOf('blob:') === 0) {
            this.setSrc(this.objectURL = this.apiSrc);
        } else if (this.apiSrc.indexOf('data:') === 0) {
            this.setSrc(this.apiSrc);
        } else {
            this.apiService.getBlob(this.apiSrc, null, {
                strictUrl: true,
                createObjectUrl: true,
                httpCache: true
            }).subscribe(resp => {
                this.setSrc(this.objectURL = resp.data);
                this.unbinds();
            });
        }
    }

    private setSrc(url: string) {
        this.$el.nativeElement.setAttribute('src', url);

        if (this.aHref) {
            this.$el.nativeElement.parentNode.setAttribute('href', url);
        }
    }

    private unbinds() {
        if (this.objectURL) {
            this.window.URL.revokeObjectURL(this.objectURL.data);
        }

        return this.objectURL = null;
    }

}
