import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormBuilder, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormatPipeModule } from '../core/pipes/format-pipe.module';

@NgModule({
    declarations: [LoginComponent, AuthComponent, SignUpComponent, ForgotPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AuthRoutingModule,
        FormatPipeModule
    ],
    providers: [
        FormBuilder
    ]
})
export class AuthModule { }
