import { Injectable } from '@angular/core';
import { Settings } from '../model';
import { SettingsProvider } from '../app-load/settings.provider';

@Injectable()
export class SettingsService {
    private settings: Settings;

    constructor(private settingsProvider: SettingsProvider) {
    }

    get(): Settings {
        if (!this.settings) {
            return this.settings = this.settingsProvider.getSettings();
        }

        return this.settings;
    }

    getLoginSlides() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.productSettings.loginSlides;
    }

    getWebsiteUrl() {
        if (!this.settings) {
            this.settings = this.settingsProvider.getSettings();
        }

        return this.settings.websiteUrl;
    }
}
