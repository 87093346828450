import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, CFG, Settings, SettingsService, UtilService } from '../../core';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.less']
})
export class SignUpComponent implements OnInit {
    signupF: FormGroup;
    logoSrc: string;
    settings: Settings;
    CFG = CFG;
    $msg;
    success;
    websiteUrl;

    constructor(
        private fb: FormBuilder,
        private authSvc: AuthService,
        private settingsSvc: SettingsService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        this.logoSrc = `${environment.apiUrl}settings/photo/logo`;
        this.websiteUrl = this.settingsSvc.getWebsiteUrl();

        this.signupF = this.fb.group({
            title: ['', Validators.required],
            forename: ['', Validators.required],
            surname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            propertyAddress: ['', Validators.required],
            contactDetails: ['', Validators.required]
        });

        this.settings = this.settingsSvc.get();
    }

    onSubmit(): void {
        if (!this.signupF.valid) {
            this.utilService.markFormGroupDirty(this.signupF);
            return;
        }
        // Disable the form
        this.signupF.disable();

        if (this.settings.name === 'Jones Robinson') {
            Object.assign(this.signupF.value, {
                branchID: '0004-2a41-b964-7a53'
            });
        }

        // Call signup request
        this.authSvc.signup(this.signupF.value).subscribe(
            data => {
                this.setMsg(CFG.MSG['M00' + (data && 1 || 2)], true);
            },
            () => {
                // Enable the form
                this.signupF.enable();
                this.setMsg(CFG.ERROR.E008);
            }
        );
    }

    private setMsg(v, success?) {
        this.$msg = v;
        this.success = success;
    }

}
