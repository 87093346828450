import { environment } from 'src/environments/environment';
const liveApiUrl = 'https://api.innovagent.property/landlord/v1/';

// Live API URL
// const apiUrl = 'https://api.innovagent.property/landlord/v1/';
// Dev API URL
const apiUrl = environment.apiUrl;
// For Nottingham - https://portal.harrisonmurray.co.uk/
//                const apiUrl = 'https://portal.harrisonmurray.co.uk/api/landlord/v1/';
export const CFG = Object.freeze({
    identifier: 'v2/customer',
    postPropertyIdentifier: 'v3/thirdparty',
    tierV2Identifier: 'v2/tier1',
    customV2Identifier: 'v2/customer',
    cache: {
        enabled: true,
        dataCompress: true,
        shortDuration: 900 * 1000, // in ms 15 min
        maxSize: 5 * 1024 * 1024, // 5 MB
        offsetSize: 0.5 * 1024 * 1024 // 0.5 MB
    },
    slider: {
        time: 5, // Display time of one slide
        shiftTime: 1, // Sliding time
        direction: -1 // -1 -> From right to left, 1 -> From left to right
    },
    idle: {
        minutesUntilLogout: 15,
        checkInterval: 15000,
        storeKey: 'lastAction'
    },
    api: {
        urlMain: 'https://innovagent.property/',
        url: {
            innovLive: liveApiUrl,
            innov: apiUrl,
            proxy: apiUrl + 'call',
            proxySession: apiUrl + 'session',
            session: '/session',
            landlord: '/landlord/',
            branch: '/branch/',
            postProperty: '/property/',
            property: '/'
        }
    },
    ERROR: {
        E001: '{0} cannot be blank.',
        E002: '{0} is not valid.',
        E003: 'Login details incorrect, please try again.',
        E004: 'We cannot find the page you\'re looking for.',
        E005: 'Try going back to the previous page, hopefully, it was just a connection issue.',
        E006: 'Your session expired.',
        E007: 'The email address you have entered is not registered.',
        E008: 'Oops, something has gone wrong...',
        E009: 'Oops, something has gone wrong with the configuration of your account.',
        E010: 'Please get in touch with our Support Team to get this issue looked at.'
    },
    MSG: {
        M001: 'Check your email as we\'ve sent you a reminder.',
        M002: 'The form has been submitted successfully. We will be in touch shortly.'
    },
    certificatesAccept: '.pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.gif,.png,.jpg,.jpeg,.bmp,.msg,.eml',
});
