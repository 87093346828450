import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-social-template',
    template: `
        <div class="social-ct">
            <a *ngIf="displayCompanyPhoneNumber && settings.telephoneSales && isMobile" href="tel:{{ settings.telephoneSales }}">
                <i class="fa fa-phone" aria-hidden="true"></i>
            </a>
            <a *ngIf="displayCompanyPhoneNumber && settings.telephoneSales && !isMobile" href="javascript:void(0)" (click)="clickMobile()">
                <i class="fa fa-phone icon-reverse icon-reverse" aria-hidden="true"></i>
                <div *ngIf="showPhoneNumber" class="phone-number animated bounceIn"> {{ settings.telephoneSales }}</div>
            </a>
            <a *ngIf="settings.facebookUrl" href="{{settings.facebookUrl}}" target="_blank"><i class="fab fa-facebook-f"></i></a>
            <a *ngIf="settings.twitterUrl" href="{{settings.twitterUrl}}" target="_blank"><i class="fab fa-twitter"></i></a>
            <a *ngIf="settings.linkedInUrl" href="{{settings.linkedInUrl}}" target="_blank"><i class="fab fa-linkedin-in"></i></a>
        </div>`,
})
export class SocialTemplateComponent {
    @Input() settings: any;

    isMobile: boolean;
    showPhoneNumber = false;

    constructor(private _deviceSvc: DeviceDetectorService) {
        this.isMobile = _deviceSvc.isMobile();
    }

    clickMobile() {
        this.showPhoneNumber = !this.showPhoneNumber;
    }

    get displayCompanyPhoneNumber() {
        return this.settings && this.settings.productSettings.displayCompanyPhoneNumber || false;
    }
}
