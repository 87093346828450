import { FormControl, Validators } from '@angular/forms';

export const formFields = Object.freeze({
    PropertyAddress: new FormControl('', Validators.required),
    Beds: new FormControl('', Validators.required),
    RentAmount: new FormControl('', Validators.required),
    Bond: new FormControl(''),
    StartDate: new FormControl(null, Validators.required),
    Description: new FormControl(''),
    Notes: new FormControl(''),
    CertHmoRefernceNumberAndExpiryDate: new FormControl(''),
    CertGasExpiryDate: new FormControl(''),
    CertEpcReferenceNumber: new FormControl(''),
    CertElectricalExpiryDate: new FormControl(''),
});

export interface Files {
    files: ImageModel[];
}

interface ImageModel {
    base64?: string | ArrayBuffer;
    name?: string;
    mimeType?: string;
}
