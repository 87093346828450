import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-dl-btn',
    template: `
    <a (click)="onStartDl()" [appApiHref]="href" (onEndDl)="onEndDl()"
        target="_blank" class="btn dl-btn" [class.disabled]="disabled">
            Download
            <i class="fa" [ngClass]="{'fa-spinner fa-spin fa-fw': disabled, 'fas fa-cloud-download-alt': !disabled}"
                aria-hidden="true"></i>
    </a>
  `,
})
export class DlBtnComponent {
    @Input() href: object;
    disabled: boolean;

    constructor() { }

    onStartDl() {
        console.log('started');
        this.disabled = true;
    }

    onEndDl() {
        console.log('ended');
        this.disabled = false;
    }

}
