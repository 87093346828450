import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { PropertyService, SummaryModel } from '../core';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardResolver implements Resolve<SummaryModel> {

  constructor(private propertyService: PropertyService) { }

  resolve(): Observable<SummaryModel> {
    return this.propertyService.getSummaryOfExistingTenancies();
  }

}
