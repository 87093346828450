import { Component, OnInit } from '@angular/core';
import { ErrorService, CFG } from '../core';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    message: string;
    CFG = CFG;

    constructor(private errorService: ErrorService) { }

    ngOnInit() {
        this.message = this.errorService.get() || CFG.ERROR.E004;
        this.errorService.set(null);
    }

}
