import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ApiService,
    UtilService,
    SettingsService,
    AppService,
    PropertyService,
    ErrorService
} from './services';
import {
    ReadMoreComponent,
    DlBtnComponent,
    ApiSrcDirective,
    CounterDirective,
    CompanyCssDirective,
    ApiHrefDirective,
    DashboardDirective,
    ChartResizeDirective
} from './directives';
import { AuthService, TokenService, AuthGuardService, AuthIdleService } from './auth';
import { windowFactory, WINDOW } from './window-factory';
import { DashboardResolver } from '../dashboard/dashoard-resolver.service';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormatCellPipeModule } from './pipes/format-cell.module';
import { TableLayoutComponent } from './shared/table-layout/table-layout.component';

@NgModule({
    declarations: [
        ReadMoreComponent,
        DlBtnComponent,
        ApiSrcDirective,
        CounterDirective,
        CompanyCssDirective,
        ApiHrefDirective,
        DashboardDirective,
        ChartResizeDirective,
        TableLayoutComponent
    ],
    imports: [
        CommonModule,
        NgxPaginationModule,
        FormatCellPipeModule
    ],
    exports: [
        ReadMoreComponent,
        DlBtnComponent,
        ApiSrcDirective,
        CounterDirective,
        CompanyCssDirective,
        ApiHrefDirective,
        DashboardDirective,
        ChartResizeDirective,
        NgxGalleryModule,
        TableLayoutComponent
    ],
    providers: [
        { provide: WINDOW, useFactory: windowFactory },
        ApiService,
        AppService,
        PropertyService,
        ErrorService,
        UtilService,
        AuthService,
        AuthIdleService,
        TokenService,
        SettingsService,
        AuthGuardService,
        DashboardResolver
    ]
})
export class CoreModule { }
