import { ColumnSetting } from '../core/shared/table-layout/layout.model';
import { PaginationSettings } from '../core/shared/table-layout/table.model';

export const maintenanceTable: ColumnSetting[] = [
    {
        primaryKey: 'Property',
        header: 'Property'
    },
    {
        primaryKey: 'Tenant',
        header: 'Contract holder'
    },
    {
        primaryKey: 'OutstandingRent',
        header: 'Outstanding',
        format: 'currency',
        append: true
    },
    {
        primaryKey: 'TenancyState',
        header: 'Status',
        format: 'string'
    }
];
export const maintenancePagination: PaginationSettings = {
    id: 'p1',
    itemsPerPage: 9,
    maxSize: 9
};

export const notesTable: ColumnSetting[] = [
    {
        primaryKey: 'Date',
        header: 'Date'
    },
    {
        primaryKey: 'Note',
        header: 'Note'
    }
];
export const notesPagination: PaginationSettings = {
    id: 'p2',
    itemsPerPage: 5,
    maxSize: 9
};
