import { Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    // Observable string source.
    private routeDataBS$: BehaviorSubject<Data> = new BehaviorSubject<Data>({});

    // Observable string stream.
    routeData$: Observable<Data> = this.routeDataBS$.asObservable();

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute) {

        // Updates the page title based on route changes.
        this.router.events
            .pipe(
                // Filter the events by the `NavigationEnd` type.
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                // Swap what we’re observing, return the `activatedRoute` into the stream.
                map(() => this.activatedRoute),
                // Find and return the deeper children property.
                map((route: ActivatedRoute) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                // Use routes only for the `primary outlet`.
                filter((route: ActivatedRoute) => route.outlet === 'primary'),
                mergeMap((route: ActivatedRoute) => route.data)
            )
            .subscribe((data: Data) => {
                // Add the route data to the stream.
                this.routeDataBS$.next(data);
            });
    }
}
