import { environment } from 'src/environments/environment';
import { Component, OnInit, HostBinding } from '@angular/core';
import { SideNavService } from './sidenav.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Settings, SettingsService, AuthService, CFG } from '../../core';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.less'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state('out', style({
                transform: 'translate3d(241px, 0, 0)'
            })),
            transition('in => out', animate('300ms cubic-bezier(0, 0, 0.3, 1)')),
            transition('out => in', animate('300ms cubic-bezier(0, 0, 0.3, 1)'))
        ]),
    ]
})
export class SidenavComponent implements OnInit {
    menuState = 'out';
    loggedIn = false;
    settings: Settings;
    CFG: any;
    devVersion = true;
    constructor(
        public sideNavServie: SideNavService,
        private authSvc: AuthService,
        private settingsSvc: SettingsService
    ) { }

    ngOnInit() {
        this.devVersion = !environment.production;
        // Create reference to config
        this.CFG = CFG;

        this.settings = this.settingsSvc.get();

        this.authSvc.isAuthenticated$.subscribe(resp => {
            this.loggedIn = resp;
        });

        this.sideNavServie.state$.subscribe(show => {
            this.menuState = show ? 'in' : 'out';
        });
    }

    logout() {
        this.authSvc.logout();
    }

}
