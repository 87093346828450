import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { Settings, SettingsService, CFG, AppService } from '../core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SideNavService } from './sidenav/sidenav.service';
import { environment } from 'src/environments/environment';
import { WINDOW } from '../core/window-factory';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit, OnDestroy {
    title: string;
    year: number;
    settings: Settings;
    showFooter = true;
    CFG: any;
    markVisibility;
    menuState;
    mOpen = false;
    logoSrc;
    private ls: any;

    _currentTitle;
    _defaultTitle = 'Dashboard';

    private appSvcRouteDataSubsciption: Subscription;

    constructor(
        private appSvc: AppService,
        private settingsSvc: SettingsService,
        private sidenavSvc: SideNavService,
        private titleService: Title,
        @Inject(DOCUMENT) private document: HTMLDocument,
        @Inject(WINDOW) private window: Window
    ) {
        this.ls = this.window.localStorage;
    }

    @HostListener('window:beforeunload', ['$event'])
    canDeactivate(event: BeforeUnloadEvent): boolean {
        const token = this.ls.getItem('idash_token');

        if (token && environment.production) {
            const req = new XMLHttpRequest();
            req.open('POST', 'script/logout.php', true);
            req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            req.send('token=' + token);

            this.ls.clear();

            // Do a little delay, just for the request for sure can be fired.
            const start = +new Date;
            while ((+ new Date - start) < 200) { }

            return true;
        }

        return true;
    }

    ngOnInit() {
        // Init mark visibility
        this.markVisibility = 'hidden';

        // Get settings from service
        this.settings = this.settingsSvc.get();

        // Create reference to config
        this.CFG = CFG;

        // Get Title
        this.appSvcRouteDataSubsciption = this.appSvc
            .routeData$
            // .pipe(
            //     take(1)
            // )
            .subscribe((routeData: any) => {
                this.title = routeData.title || '';
                this.setPageTitle(this.title);
                this.title === 'Error' ? this.showFooter = false : this.showFooter = true;
                this.sideNavClose();
                window.scroll(0, 0);
            });

        // Init year for footer
        this.year = new Date().getFullYear();

        this.logoSrc = `${environment.apiUrl}settings/photo/logo`;
        this.setFavicon();
    }

    ngOnDestroy() {
        this.appSvcRouteDataSubsciption.unsubscribe();
    }

    sideNavOpen() {
        this.markVisibility = 'visible';
        this.sidenavSvc.open();
        this.mOpen = true;
    }

    sideNavClose() {
        this.markVisibility = 'hidden';
        this.sidenavSvc.close();
        this.mOpen = false;
    }

    lowercase(text) {
        return text.toLowerCase();
    }

    private setPageTitle(value: string) {
        this._currentTitle = value ? (this.settings.name ? this.settings.name + ' - ' + value : value) : this._defaultTitle;
        this.titleService.setTitle(this._currentTitle);
    }

    private setFavicon() {
        const faviconSrc = CFG.api.url.innovLive + 'settings/photo/';
        this.document.getElementById('appFavicon').setAttribute('href', faviconSrc + 'favicon?dc=' + new Date().getTime());
    }

}
