export class ColumnSetting {
    primaryKey: string;
    header?: string;
    headerClass?: string;
    format?: string;
    templateDlBtn?: boolean;
    prepend?: string;
    append?: true;
    readMore?: boolean;
    alternativeKeys?: string[];
    permissionName?: string;
}

export class ColumnMap {
    primaryKey: string;
    private _header: string;
    private _format: string;
    headerClass: string;
    readMore: boolean;
    templateDlBtn: string;
    prepend: string;
    append: true;
    alternativeKeys?: string[];

    constructor(settings) {
        this.primaryKey = settings.primaryKey;
        this.header = settings.header;
        this.headerClass = settings.headerClass || '';
        this.readMore = settings.readMore;
        this.prepend = settings.prepend;
        this.append = settings.append;
        this.format = settings.format;
        this.templateDlBtn = settings.templateDlBtn;
        this.alternativeKeys = settings.alternativeKeys;
    }

    set header(setting: string) {
        // this._header = setting ?
        //     setting :
        //     this.primaryKey.slice(0, 1).toUpperCase() +
        //     this.primaryKey.replace(/_/g, ' ').slice(1);
        this._header = setting;
    }

    get header() {
        return this._header;
    }

    set format(setting: string) {
        this._format = setting ? setting : 'default';
    }

    get format() {
        return this._format;
    }

    access = function (object: any) {
        if (object[this.primaryKey] || !this.alternativeKeys) {
            return this.primaryKey;
        }
        for (const key of this.alternativeKeys) {
            if (object[key]) {
                return key;
            }
        }
        return this.primaryKey;
    };
}
