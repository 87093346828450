import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTPListener, HTTPStatus, /*CachingInterceptor*/ } from './httpInterceptor';
import { AppRoutingModule } from './app-routing.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CommonModule } from '@angular/common';
import { FormatPipeModule } from './core/pipes/format-pipe.module';
import { CoreModule } from './core/core.module';
import { SettingsProvider } from './core/app-load/settings.provider';
import { GoogleChartsModule } from 'angular-google-charts';
import { ErrorComponent } from './error/error.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccountComponent } from './account/account.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { EditPropertyComponent } from './edit-property/edit-property.component';
import { ArrearsComponent } from './arrears/arrears.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { PropertyComponent } from './property/property.component';
import { LayoutModule } from './layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { AddPropertyGuardService } from './core/services/add-property.guard';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from './core/dateformat';
import {NgxPaginationModule} from 'ngx-pagination';
import {FormatCellPipeModule} from './core/pipes/format-cell.module';
import {AgmCoreModule} from '@agm/core';

const RxJS_Services = [HTTPListener, HTTPStatus];

export function settingsProviderFactory(provider: SettingsProvider) {
    return () => provider.load();
}
@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        DashboardComponent,
        AccountComponent,
        MaintenanceComponent,
        ArrearsComponent,
        PropertyComponent,
        AddPropertyComponent,
        EditPropertyComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        GooglePlaceModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDUhXv8TGC20HEZtk8ABUDb5Qnyb8NswyA'
        }),
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 5500,
            positionClass: 'toast-top-center'
        }
        ),
        HttpClientModule,
        CoreModule,
        LayoutModule,
        AuthModule,
        AppRoutingModule,
        GoogleChartsModule.forRoot(),
        ReactiveFormsModule,
        FormatPipeModule,
        Angular2AirDatepickerModule,
        NgbModule,
        DeviceDetectorModule.forRoot(),
        NgxPaginationModule,
        FormatCellPipeModule
    ],
    providers: [
        SettingsProvider,
        AddPropertyGuardService,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        {
            provide: APP_INITIALIZER,
            useFactory: settingsProviderFactory,
            deps: [SettingsProvider, HttpClient],
            multi: true
        },
        ...RxJS_Services,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPListener,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CachingInterceptor,
        //     multi: true
        // }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
