import { FormControl, Validators } from '@angular/forms';

export const form1Fields = Object.freeze({
    Address1: new FormControl('', Validators.required),
    Address2: new FormControl('', Validators.required),
    Address3: new FormControl('', Validators.required),
    Address4: new FormControl(''),
    Postcode: new FormControl('', Validators.required),
    BranchId: new FormControl('', Validators.required)
});

export const form2Fields = Object.freeze({
    NoOfBedrooms: new FormControl('', Validators.required),
    NoOfBathrooms: new FormControl('', Validators.required),
    ProperyType: new FormControl('', Validators.required)
});

export const form3Fields = Object.freeze({
    AdvertisingRent: new FormControl('', Validators.required),
    Bond: new FormControl(''),
    InstructionTermStart: new FormControl(null, Validators.required),
    water: new FormControl(false),
    gas: new FormControl(false),
    electricity: new FormControl(false),
    cleaning: new FormControl(false),
    internet: new FormControl(false),
    gardening: new FormControl(false)
});

export const form4Fields = Object.freeze({
    Description: new FormControl(''),
    fridge: new FormControl(false),
    washingMachine: new FormControl(false),
    wheelchairAccess: new FormControl(false),
    telephone: new FormControl(false),
    internetF: new FormControl(false),
    doubleGlazing: new FormControl(false),
    enSuite: new FormControl(false),
    parkingSpaces: new FormControl(''),
    burglarAlarm: new FormControl(false),
    quickReleaseThumbLock: new FormControl(false),
    mainsWiredHeatDetector: new FormControl(false),
    mainsWiredSmokeDetectionSystem: new FormControl(false),
    centralHeating: new FormControl(false),
    electricHeaters: new FormControl(false),
    gasHeaters: new FormControl(false),
    electric: new FormControl(false),
    combiBoiler: new FormControl(false),
    hmo: new FormControl(false),
    epc: new FormControl(false),
    gasCertificate: new FormControl(false),
    electricalExpiryDate: new FormControl(false),
    tenancyDepositSchemeInformation: new FormControl(false),
});

export const form5Fields = Object.freeze({
    VideoUrl: new FormControl('')
});

export interface Files {
    files: ImageModel[];
}

interface ImageModel {
    base64?: string | ArrayBuffer;
    name?: string;
    mimeType?: string;
}
