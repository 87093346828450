import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { TokenService } from './token.service';
import { UtilService } from '../services/util.service';
import { ErrorService } from '../services/error.service';
import { CFG } from '../config';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private isAuthenticatedBS$ = new BehaviorSubject<boolean>(!!this.tokenSvc.token);
    public isAuthenticated$ = this.isAuthenticatedBS$.asObservable();

    // Store the URL so we can redirect after logging in.
    redirectUrl: string;

    constructor(private httpClient: HttpClient,
        private router: Router,
        private utilSvc: UtilService,
        private tokenSvc: TokenService,
        private errorService: ErrorService
    ) {

        if (this.tokenSvc.token) {
            this.isAuthenticatedBS$.next(true);
        }
    }

    login(credentials: Credentials): Observable<any> {
        return this._requestWrapper(credentials, 'post', null, true);
    }

    reset(credentials) {
        return this._requestWrapper(credentials, 'post', 'resetpassword', true);
    }

    signup(params) {
        return this._requestWrapper(params, 'post', 'createlandlordlogin', true);
    }

    logout(): void {
        // Clear the current auth data.
        this.purgeAuth();
        localStorage.clear();

        // Redirect to login.
        this.router.navigate(['/login']);
    }

    onError(error: string) {
        this.errorService.set(error);
        this.router.navigate(['/error']);
    }

    private _requestWrapper(params = {}, method, methodName, sessionCtrl) {
        methodName = methodName && '/' + methodName || '';

        const urls = CFG.api.url;

        return this.httpClient.post<ApiResponse>(
            urls['proxy' + (sessionCtrl && 'Session' || '')],
            JSON.stringify(params),
            this.utilSvc.buildHttpRequestOptions(null, {
                identifier: CFG.identifier,
                'path-query': urls.session + methodName + (!sessionCtrl && '?' + this.utilSvc.httpBuildQuery(params) || '')
            })
        ).pipe(
            catchError((err: HttpErrorResponse) => Observable.throw(err)),
            map((resp: ApiResponse) => {
                try {
                    // Parse and save the JWT token to the localStorage.
                    this.tokenSvc.token = resp.data;

                    // Set auth status to `true`.
                    this.isAuthenticatedBS$.next(true);
                } catch (e) {
                    return false;
                }

                return resp;
            })
        );

    }

    private purgeAuth(): void {
        // Remove the JWT token from localStorage.
        this.tokenSvc.destroyToken();

        // Set auth status to `false`.
        this.isAuthenticatedBS$.next(false);
    }
}
