import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SocialTemplateComponent } from './../core/templates';

@NgModule({
    declarations: [
        LayoutComponent,
        SidenavComponent,
        SocialTemplateComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule
    ],
    exports: [
        SocialTemplateComponent
    ]
})
export class LayoutModule { }
