import { Component, OnInit } from '@angular/core';
import { SettingsService, CFG, UtilService, Settings } from '../core';
import { environment } from 'src/environments/environment.prod';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.less']
})
export class AuthComponent implements OnInit {
    loginSlides: any[] = [];
    settings: Settings;

    constructor(
        private settingsSvc: SettingsService,
        private utilSvc: UtilService,
        private titleService: Title
    ) { }

    ngOnInit() {
        const tmpLoginSlides = this.settingsSvc.getLoginSlides();

        // Get settings from service
        this.settings = this.settingsSvc.get();
        this.titleService.setTitle(this.settings.name);

        const slidesCnt = tmpLoginSlides.length;
        const animTime = slidesCnt * CFG.slider.time;
        const translate = 'translate3d(' + this.utilSvc.percentage(CFG.slider.direction) + ',0,0)';
        const animation = `slide ${animTime}s cubic-bezier(.4,0,.2,1) 0s infinite`;

        tmpLoginSlides.forEach((image, i) => {
            const animationDelay = Math.round((CFG.slider.time * i - 0.1 - CFG.slider.time * (slidesCnt - 1)) * 10) / 10 + 's';

            this.loginSlides.push({
                style: {
                    'background-image': `url(${
                        environment.apiUrl
                        }settings/photo/slide/${i + 1})`,
                    '-webkit-transform': translate,
                    animation: animation,
                    '-webkit-animation': animation,
                    'animation-delay': animationDelay,
                    '-webkit-animation-delay': animationDelay
                }
            });
        });
    }
}
