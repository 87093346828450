import { Directive, Inject, Input, HostListener } from '@angular/core';
import { WINDOW } from '../window-factory';
import { UtilService } from '../services';

declare const $: any;

@Directive({
    selector: '[appChartResize]'
})
export class ChartResizeDirective {
    @Input() options;
    $win;

    constructor(
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window
    ) {
        this.$win = $(this.window);
    }

    @HostListener('window:resize', ['$event']) resize1() {
        if (this.options) {
            let h;
            if (this.options.height !== h) {
                this.options.height = h = this.utilService.chartMaxHeight(this.options.maxHeight);
            }
        }
    }
}
