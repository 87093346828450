import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ApiService } from './core';
import { tap } from 'rxjs/operators';

@Injectable()
export class HTTPStatus {
    private requestInFlight$: BehaviorSubject<boolean>;

    constructor() {
        this.requestInFlight$ = new BehaviorSubject(false);
    }

    setHttpStatus(inFlight: boolean) {
        this.requestInFlight$.next(inFlight);
    }

    getHttpStatus(): Observable<boolean> {
        return this.requestInFlight$.asObservable();
    }
}

@Injectable()
export class HTTPListener implements HttpInterceptor {
    constructor(private status: HTTPStatus) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            map(event => {
                if (req.responseType !== 'blob') {
                    this.status.setHttpStatus(true);
                }

                return event;
            }),
            catchError(error => {
                return throwError(error);
            }),
            finalize(() => {
                this.status.setHttpStatus(false);
            })
        );
    }
}

// @Injectable()
// export class CachingInterceptor implements HttpInterceptor {
//     constructor(private apiService: ApiService) { }
//
//     intercept(req: HttpRequest<any>, next: HttpHandler) {
//         if(req.method === "POST") {
//             return this.sendRequest(req, next, this.apiService);
//         }
//         const cachedResponse = this.apiService.getCache(req);
//         return cachedResponse ? of(cachedResponse) : this.sendRequest(req, next, this.apiService);
//     }
//
//     sendRequest(
//         req: HttpRequest<any>,
//         next: HttpHandler,
//         apiService: ApiService): Observable<HttpEvent<any>> {
//         return next.handle(req).pipe(
//             tap(event => {
//                 if (event instanceof HttpResponse) {
//                     apiService.putCache(req, event);
//                 }
//             })
//         );
//     }
// }
