import { Injectable, Inject } from '@angular/core';
import { AuthService } from './auth.service';
import { CFG } from '../config';
import { WINDOW } from '../window-factory';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthIdleService {
    private ls: any;
    resetBound;

    public getLastAction() {
        // tslint:disable-next-line:radix
        return parseInt(localStorage.getItem(CFG.idle.storeKey));
    }
    public setLastAction(lastAction: number) {
        this.ls.setItem(CFG.idle.storeKey, lastAction.toString());
    }

    constructor(
        private authSvc: AuthService,
        private toastr: ToastrService,
        @Inject(WINDOW) private window: Window
    ) {
        this.ls = this.window.localStorage;
    }

    start() {
        this.ls.removeItem(CFG.idle.storeKey);
        this.check();
        this.initListener();
        this.initInterval();
    }

    initListener() {
        this.resetBound = this.reset.bind(this);
        document.body.addEventListener('click', this.resetBound, false);
        document.body.addEventListener('mouseover', this.resetBound, false);
        document.body.addEventListener('mouseout', this.resetBound, false);
        document.body.addEventListener('keydown', this.resetBound, false);
        document.body.addEventListener('keyup', this.resetBound, false);
        document.body.addEventListener('keypress', this.resetBound, false);
    }

    reset() {
        this.setLastAction(Date.now());
    }

    initInterval() {
        setInterval(() => {
            this.check();
        }, CFG.idle.checkInterval);
    }

    check() {
        const now = Date.now();
        const timeleft =
            this.getLastAction() + CFG.idle.minutesUntilLogout * 60 * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        if (isTimeout) {
            this.done();
        }
    }

    private done() {
        document.body.removeEventListener('click', this.resetBound, false);
        document.body.removeEventListener('mouseover', this.resetBound, false);
        document.body.removeEventListener('mouseout', this.resetBound, false);
        document.body.removeEventListener('keydown', this.resetBound, false);
        document.body.removeEventListener('keyup', this.resetBound, false);
        this.toastr.info(CFG.ERROR.E006);
        this.authSvc.logout();
    }
}
