import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CFG } from '../config';
import { Settings } from '../model';


@Injectable()
export class SettingsProvider {

    private settings: any = null;

    constructor(private http: HttpClient) {
    }

    public getSettings(): any {
        return new Settings(this.settings);
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get(CFG.api.url.innov + 'settings')
                .subscribe((response: ApiResponse) => {
                    this.settings = response.data;

                    this.settings.app = {
                        loggedIn: false,
                        year: new Date().getFullYear(),
                        needSocial: this.settings.telephoneSales || this.settings.facebookUrl || this.settings.twitterUrl ||
                            this.settings.googlePlusUrl || this.settings.linkedInUrl
                    };

                    this.settings = new Settings(this.settings);

                    resolve(true);
                },
                    (err) => {
                        resolve(false);
                    });
        });
    }
}
