import {Component, OnInit, ViewChild, Inject, ChangeDetectorRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {GooglePlaceDirective} from 'ngx-google-places-autocomplete';
import {CFG, BranchModel, UtilService, ApiService, PropertyService} from '../core';
import {LandlordCrmEntry} from '../core/model/landlordCrmEntry.model';
import {WINDOW} from '../core/window-factory';
import {formFields, Files} from './edit-property-form';
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import { Upload, UploadObj } from '../add-property/file-upload.config';

@Component({
    selector: 'app-edit-property',
    templateUrl: './edit-property.component.html',
    styleUrls: ['./edit-property.component.less', './styles.less']
})
export class EditPropertyComponent implements OnInit {
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    tenancyId: string;
    globalId: string;
    form: FormGroup;
    CFG = CFG;
    billsIncluded = false;
    section = 1;
    date: Date = new Date;
    displayDatePicker = false;
    branch: BranchModel;
    landlordCrmEntries: LandlordCrmEntry[];
    branches: BranchModel[];
    showBranchField = false;
    success = null;
    error = null;
    isErrorMessage = false;
    upload = new UploadObj();
    U = Upload;
    ls;

    oldObject;

    constructor(
        @Inject(WINDOW) private _window: Window,
        private _route: ActivatedRoute,
        private _propertySvc: PropertyService,
        private _apiSvc: ApiService,
        private _ngbDateParserFormatter: NgbDateParserFormatter,
        private _chdRef: ChangeDetectorRef,
        private _router: Router,
        private _utilSvc: UtilService) {
        this.ls = _window.localStorage;
    }

    ngOnInit() {
        this.form = new FormGroup(_.cloneDeep(formFields));
        this.tenancyId = this._route.snapshot.paramMap.get('id');

        this._propertySvc.getTenancy(
            {tenancyID: this.tenancyId},
            {cacheKey: `tenancy-${this.tenancyId}`}
        )
            .subscribe(data => {
                let tenancy = data.data;
                const tenancies = JSON.parse(this.ls[`summary`]).data.Tenancies;
                tenancy = Object.assign(tenancy, _.find(tenancies, {ID: this.tenancyId}));
                this.globalId = tenancy.GlobalReference;

                if (tenancy.StartDate) {
                    const startDate = new Date(tenancy.StartDate);
                    tenancy.StartDate = {
                        day: startDate.getDay(),
                        month: startDate.getMonth() + 1,
                        year: startDate.getFullYear()
                    };
                }

                this.form.patchValue(tenancy);
                this.updateCertificates(tenancy.Certificates);
                this.oldObject = _.cloneDeep(this.form.value);
            });
    }

    save() {
        if (!this.form.valid) {
            this._utilSvc.markFormGroupDirty(this.form);
            return false;
        }
        const value = this.form.value;

        const attachments = [];
        this.upload.getResult().forEach(res => {
            attachments.push(this.createAttachment(res.details, res.type, res.files));
        });

        if (JSON.stringify(this.oldObject) === JSON.stringify(value) && attachments.length === 0) {
            this._router.navigate(['/dashboard']);
            return;
        }

        const diff = UtilService.difference(this.form.value, this.oldObject);
        if (diff['StartDate']) {
            const tmpStartDate = diff.StartDate;
            diff['StartDate'] = new Date(Date.UTC(tmpStartDate.year, tmpStartDate.month - 1, tmpStartDate.day)).toISOString() || '';
        }

        const data = {
            ...diff,
            ...{Attachments: attachments},
            ...{GlobalReference: this.globalId}
        };

        this._apiSvc.post(
            'property/update',
            data,
            {},
            false
        ).subscribe(
            resp => {
                this.success = `Thanks for submitting your amended details.
                We will check and confirm these updates before setting them live on our system.`;
                this.isErrorMessage = false;
            }, error => {
                this.isErrorMessage = true;
                this.error = 'Something went wrong, please try again later.';
            }
        );
    }

    updateCertificates(certificates: any[]): void {
        certificates.forEach(c => {
            if (c.Type === 'Energy Performance (EPC)' && c.Status === 'Active') {
                this.form.patchValue({CertEpcReferenceNumber: true});
            }

            if (c.Type === 'Fire Alarm' && c.Status === 'Active') {
                this.form.patchValue({CertElectricalExpiryDate: true});
            }

            if (c.Type === 'Gas Safety Certificate' && c.Status === 'Active') {
                this.form.patchValue({CertGasExpiryDate: true});
            }

            if (c.Type === 'HMO Licence' && c.Status === 'Active') {
                this.form.patchValue({CertHmoRefernceNumberAndExpiryDate: true});
            }
        });
    }

    handleFileInput($event, type: Upload) {
        const files = $event.target.files;

        this.upload.obj[type].files = [];

        if (files && files.length) {
            // Clear the error state before processing the new files
            this.upload.obj[type].errorFileSize = false;
            this.upload.obj[type].errorType = false;

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader(),
                    file = files[i];

                reader.onload = () => {
                    if (this.upload.obj[type].maxFileSize < file.size) {
                        this.upload.obj[type].errorFileSize = true;
                    } else {
                        this.upload.obj[type].errorFileSize = false;
                        this.upload.obj[type].errorType = false;
                        this.upload.isAccepted({ type, reader, file });
                    }

                    // Need to run CD since file load runs outside of the zone
                    this._chdRef.markForCheck();
                };

                reader.readAsDataURL(file);
            }
        }
    }

    private createAttachment(details, type, files): any {
        const attachment = {
            Details: details,
            AttachmentType: type,
            Documents: []
        };

        files.forEach(f => {
            attachment.Documents.push({
                Name: f.name,
                Contents: f.base64,
                DocumentType: 'General',
                MimeType: f.mimeType,
                ExternalDocument: true,
                PublicLink: true
            });
        });
        return attachment;
    }
}
