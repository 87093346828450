import { CFG } from "../core/config";

export enum Upload {
    images = 1,
    floorplan = 2,
    epc = 3,
    certificates = 4,
}

const errorMessages = {
    [Upload.images]: 'Only images are allowed.',
    [Upload.floorplan]: 'Only images ad PDF files are allowed.',
    [Upload.epc]: 'Only images ad PDF files are allowed.',
    [Upload.certificates]: 'Only files with these extensions are allowed: pdf, doc, docx, xls, xlsx, csv, txt, gif, png, jpg, jpeg, bmp, msg, eml.'
}

export class UploadObj {
    obj = {
        [Upload.images]: {
            files: [],
            details: 'Property Photos',
            type: 'PropertyPhotos',
            maxFileSize: 18 * 2 ** 20,
            errorFileSize: false,
            errorType: false,
        },
        [Upload.floorplan]: {
            files: [],
            details: 'Floor Plan',
            type: 'FloorPlan',
            maxFileSize: 18 * 2 ** 20,
            errorFileSize: false,
            errorType: false,
        },
        [Upload.epc]: {
            files: [],
            details: 'EPC',
            type: 'EPC',
            maxFileSize: 18 * 2 ** 20,
            errorFileSize: false,
            errorType: false,
        },
        [Upload.certificates]: {
            files: [],
            details: 'Certificate',
            type: 'General',
            maxFileSize: 18 * 2 ** 20,
            errorFileSize: false,
            errorType: false,
        }
    };

    certificatesAccept = CFG.certificatesAccept;

    isAccepted(params: Params) {
        const fileType = params.file.type;

        switch(params.type) {
            case Upload.images:
                return this.checkCondition(fileType === 'image/jpeg' || fileType === 'image/png', params);

            case Upload.floorplan:
                return this.checkCondition(fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf', params);

            case Upload.epc:
                return this.checkCondition(fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'application/pdf', params);

            case Upload.certificates:
                return this.checkCondition(new RegExp(CFG.certificatesAccept.split(',').join("|")).test(params.file.name.toLowerCase()), params);

            default: return false;
        }
    }

    getResult(): any[] {
        const result = [];
        Object.keys(this.obj).forEach(key => {
            if (this.obj[key].files.length) {
                result.push(this.obj[key]);
            }
        });
        return result;
    }

    private checkCondition(accepted: boolean, params: Params): boolean {
        if (!accepted) {
            this.obj[params.type].errorType = true;
            return false;
        }
        this.obj[params.type].errorType = false;
        this.obj[params.type].errorFileSize = false;

        this.obj[params.type].files.push({
            base64: params.reader.result,
            name: params.file.name,
            mimeType: params.file.type
        });

        return true;
    }
}

interface Params {
    type: Upload;
    reader: FileReader;
    file: File;
}

