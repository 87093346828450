import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Settings, SettingsService } from './../../core';
import { UtilService } from '../services';

@Directive({
    selector: '[appCompanyCss]'
})
export class CompanyCssDirective implements OnInit {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private settingsSvc: SettingsService,
        private utilService: UtilService
    ) { }

    ngOnInit() {
        const styleEl = document.createElement('style'),
            cCfg: Settings = this.settingsSvc.get();

        let baseCol,
            baseOpa5Col,
            headerFooterHoverCol,
            headerTxtCol;

        if (cCfg.color1) {
            baseCol = '#' + cCfg.color1;
            baseOpa5Col = 'rgba(' + this.utilService.hexToRgb(this.utilService.shadeHex(baseCol, -0.3)) + ',.5)';
        }

        if (cCfg.color2) {
            headerFooterHoverCol = '#' + cCfg.color2;
        }

        if (cCfg.color3) {
            headerTxtCol = '#' + cCfg.color3;
        }

        document.body.appendChild(styleEl);

        const styles = `
                .btn,
                button.btn,
                input[type=submit].btn,
                input[type=button].btn,
                .ng-toast__message,
                table .thead,
                #side-nav,
                .card.top-dashboard .card-image {
                    background-color: ` + baseCol + `;
                }
                .btn:hover,
                button.btn:hover,
                input[type=submit].btn:hover,
                input[type=button].btn:hover,
                .main-header,
                footer {
                    background-color: ` + headerFooterHoverCol + `;
                }
                a,
                footer .social-ct a i:hover,
                footer p a:hover,
                .pagination-row td .pagination li a,
                table tr td span.more-link {
                    color: ` + baseCol + `;
                }
                a:hover {
                    color: ` + headerFooterHoverCol + `;
                }
                .table,
                table,
                table tbody,
                table thead,
                .pagination-row td .pagination li.active a {
                    border-color: ` + baseCol + `;
                }
                #side-nav li:hover,
                .card .card-title {
                    background-color: ` + baseOpa5Col + `;
                }
                table tr td span.more-link:hover {
                    color: ` + baseOpa5Col + `;
                }
                .main-header,
                #back-btn,
                #cross-btn,
                .ng-toast__message span {
                    color: ` + headerTxtCol + `;
                }
                #menu-open > span {
                    background-color: ` + headerTxtCol + `;
                }
                .main-border {
                    border: 3px solid ${baseCol};
                    border-radius: .4em;
                }
                .add-property-card .card .card-cont .back,
                .add-property-card .card .card-cont .front{
                    background-color: ${baseCol}
                }
                .notes-list li {
                    border-bottom: 1px solid ${baseCol};
                }
                .notes-list li::before {
                    content: '\\2022';
                    color: ${baseCol};
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                }
                .pagination-row ul li.current span {
                    border-color: ${baseCol};
                }
                .pagination-row ul li span.ng-star-inserted,
                .pagination-row ul li a {
                    color: ${baseCol};
                }`;

        styleEl.innerHTML = styles;
        this.renderer.appendChild(this.elementRef.nativeElement, styleEl);
    }

}
