import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsService } from '../services';
import { Settings } from '../model';

@Injectable()
export class AddPropertyGuardService implements CanActivate {
    settings: Settings;

    constructor(
        private router: Router,
        private settingsSvc: SettingsService,
    ) {
        this.settings = this.settingsSvc.get();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.settings.productSettings.enableAddPropertyListing) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }

}
