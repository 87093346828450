import { Injectable } from '@angular/core';
import { TokenService } from '../auth/token.service';
import { CFG } from '../config';
import { of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class UtilService {

    constructor(
        private tokenService: TokenService
    ) { }

    private baseHttpHeaders: { [header: string]: string } = {
        'Content-Type': 'application/json'
    };

    static difference(object, base) {
        return _.transform(object, (result, value, key) => {
            if (!_.isEqual(value, base[key])) {
                result[key] = value;
            }
        });
    }

    static pathId(path: string, id: number): string {
        return id ? `${path}/${id}` : path;
    }

    static pathIdDelete(path: string, id: number): string {
        return `${path}/${id}/delete`;
    }

    static toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    static isNumber(value: any): value is number {
        return !isNaN(this.toInteger(value));
    }

    static padNumber(value: number) {
        if (this.isNumber(value)) {
          return `0${value}`.slice(-2);
        } else {
          return '';
        }
    }

    static removeDuplicates(array) {
        return array.reduce((arr, item) => {
          const removed = arr.filter(i => i !== item);
          return [...removed, item];
        }, []);
    };

    httpBuildQuery(obj = {}, num_prefix?, temp_key?, separator = '&') {
        const output_string = [],
            keys = obj ? Object.keys(obj) : null;

        if (!keys || !keys.length) {
            return '';
        }

        keys.forEach((val) => {
            let key = val;
            if (num_prefix && !isNaN(key as any)) {
                key = num_prefix + key;
            }

            key = encodeURIComponent(key).replace(/[!‘()*]/g, escape);
            if (temp_key) {
                key = temp_key + '[' + key + ']';
            }

            if (typeof obj[val] === 'object') {
                const query = this.httpBuildQuery(obj[val], null, key);
                output_string.push(query);
            } else {
                const value = encodeURIComponent(obj[val].toString()).replace(/[!‘()*]/g, escape);
                output_string.push(key + '=' + value);
            }

        });

        return output_string.join(separator);
    }

    buildUrl(params, method = '', ctrlType = 'landlord', identifier = CFG.identifier, letmc = true): string {
        if (!letmc) {
            return `${CFG.api.url.innov}${method}`;
        }
        const urls = CFG.api.url,
            mainApiParams = {
                identifier: identifier,
                'path-query': urls[ctrlType] + method + '?' + this.httpBuildQuery(params)
            };

        return urls.proxy + '?' + this.httpBuildQuery(mainApiParams);
    }

    buildHttpRequestOptions(options, params: { [param: string]: string } = {}): {
        headers: { [header: string]: string },
        params: { [param: string]: string }
    } {
        const acceptJson = options && options['responseType'] ? false : true;

        return {
            headers: this.buildHttpHeaders(acceptJson),
            params,
            ...options
        };
    }

    buildHttpHeaders(acceptJson): { [header: string]: string } {
        const httpHeadersObj = Object.assign({}, this.baseHttpHeaders);

        if (acceptJson) {
            httpHeadersObj['Accept'] = `application/json`;
        }

        const token = this.tokenService.token;
        if (token) {
            httpHeadersObj['X-Authorization'] = `Bearer ${token}`;
        }

        return httpHeadersObj;
    }

    percentage(v) {
        return v * 100 + '%';
    }

    promise(data) {
        return of(data);
    }

    hexToRgb(hex) {
        const bigInt = parseInt(hex.slice(1), 16);
        return ((bigInt >> 16) & 255) + ',' + ((bigInt >> 8) & 255) + ',' + (bigInt & 255);
    }

    shadeHex(color, percent) {
        const f = parseInt(color.slice(1), 16),
            t = percent < 0 ? 0 : 255,
            p = percent < 0 ? percent * -1 : percent,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return '#' + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    }

    chartMaxHeight(maxH: number) {
        return Math.max(290, Math.min(maxH, window.innerHeight - 30, window.innerWidth - 30));
    }

    debounce(func, wait, immediate?, invokeApply?) {
        let timeout;

        let result;
        const context = this,
            args = arguments,
            later = () => {
                timeout = null;
                if (!immediate) {
                    result = func.apply(context, args);
                }
            },
            callNow = immediate && !timeout;

        if (timeout) {
            clearTimeout(timeout);
        }

        timeout = setTimeout(later, wait, invokeApply);

        if (callNow) {
            result = func.apply(context, args);
        }

        return result;
    }

    markFormGroupDirty(formGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsDirty();

            if (control.controls) {
                this.markFormGroupDirty(control);
            }
        });
    }
}
