import { Directive, OnInit, ElementRef, Inject, OnDestroy } from '@angular/core';
import { WINDOW } from '../window-factory';
import { UtilService } from '../services';

declare const $: any;

@Directive({
    selector: '[appDashboard]'
})
export class DashboardDirective implements OnInit, OnDestroy {
    $win;
    $el;
    constructor(
        private utilService: UtilService,
        private el: ElementRef,
        @Inject(WINDOW) private window: Window
    ) {
        this.$win = $(this.window);
        this.$el = $(this.el);
    }

    ngOnInit() {

        // Apply manual rotating just for devices
        if (this.isDevice()) {
            this.$el
                .on('click', 'div.rotating', this.onRotatingCardClick(event))
                .on('click', this.onElClick());

            this.$win.on('scroll', this.efficientScroll());
        }
    }

    onRotatingCardClick(e) {

        const $this = $(this);

        // Stop the event bubbling up to the el,
        // so the el click cannot remove the "hover" class
        e.stopPropagation();

        // When need to flip the item
        if (!$this.hasClass('hover')) {

            // Calcel the event
            e.preventDefault();

            // Add the "hover" class to the active item
            $this.addClass('hover');
        }
    }

    isDevice() {
        const userAgent = this.window.navigator.userAgent,
            platform = this.window.navigator.platform,
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];

        if (iosPlatforms.indexOf(platform) !== -1 || /Android/.test(userAgent)) {
            return true;
        }

        return false;
    }

    onElClick() {
        this.$el
            .find('div.hover')
            .removeClass('hover');
    }

    efficientScroll() {
        this.utilService.debounce(() => {

            let topOfTheWindow = this.$win.scrollTop();
            const bottomOfTheWindow = topOfTheWindow + this.$win.height();

            // Set top of the window to the visible area top,
            // so the header bottom
            topOfTheWindow += $('header.main-header ').height();

            // Remove hover class on scroll, when just the
            // 1/3 item is visible
            this.$el
                .find('div.hover')
                .each(function (i, item) {
                    const $item = $(item),
                        itemHeight = $(item).outerHeight(),
                        itemOneThirdHeight = itemHeight / 3,
                        topOfTheItem = $(item).offset().top,
                        bottomOfTheItem = topOfTheItem + itemHeight;

                    // 1/3 top of the item or 1/3 bottom of the item is't visible remove the hover class
                    if (
                        (bottomOfTheWindow < topOfTheItem + itemOneThirdHeight) ||
                        (topOfTheWindow > bottomOfTheItem - itemOneThirdHeight)
                    ) {

                        $item.removeClass('hover');
                    }
                });
        }, 250);
    }

    ngOnDestroy() {
        this.$win.off('scroll', this.efficientScroll());
    }

}
