import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {AuthService, CFG, SettingsService} from '../../core';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
    resetF: FormGroup;
    logoSrc: string;
    $msg;
    success;
    CFG = CFG;
    websiteUrl;

    constructor(
        private fb: FormBuilder,
        private authSvc: AuthService,
        private settingsSvc: SettingsService
    ) { }

    ngOnInit() {
        this.logoSrc = `${environment.apiUrl}settings/photo/logo`;
        this.websiteUrl = this.settingsSvc.getWebsiteUrl();

        this.resetF = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    onSubmit(): void {
        if (!this.resetF.valid) {
            this.resetF.controls.email.markAsDirty();
            return;
        }
        // Disable the form
        this.resetF.disable();

        // Call reset request
        this.authSvc.reset(this.resetF.value).subscribe(
            data => {
                this.setMsg(CFG.MSG.M001, true);
            },
            () => {
                // Enable the form
                this.resetF.enable();
                this.setMsg(CFG.ERROR.E007);
            }
        );
    }

    private setMsg(v, success?) {
        this.$msg = v;
        this.success = success;
    }

}
