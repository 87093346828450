import { Directive, OnInit, Input, ElementRef } from '@angular/core';

declare const $: any;

@Directive({
    selector: '[appCounter]'
})

export class CounterDirective implements OnInit {
    @Input('appCounter') appCounterInput: string;
    @Input() isNum: string;

    constructor(private $el: ElementRef) { }

    ngOnInit() {
        if (this.appCounterInput !== '') {
            const self = this.$el,
                val = this.appCounterInput,
                num = this.isNum;

            $({ c: 0 }).animate({
                c: val
            }, {
                    duration: 1000,
                    step: (now) => {
                        self.nativeElement.innerText = num &&
                            (now.toFixed(0) + ' tasks') ||
                            ('£' + now.toLocaleString('en-US', { minimumFractionDigits: 2 }));
                    }
                });
        }

    }

}