import { Component, OnInit, Input } from '@angular/core';

interface ScopeModel {
    readMore?: string;
    rmLimit?: number;
    rmMoreTxt?: string;
    rmLessTxt?: string;
    rmNeeded?: boolean;
    rmCollapsed?: boolean;
    rmHtml?: string;
    rmText?: string;
}

@Component({
    selector: 'app-read-more',
    template: `
        <span>
        <span *ngIf="!$scope.rmNeeded || !$scope.rmCollapsed" class="full-txt">{{$scope.rmHtml}}</span>
        <span *ngIf="$scope.rmNeeded && $scope.rmCollapsed">{{$scope.rmText}}</span>
        <span *ngIf="$scope.rmNeeded">
            <span *ngIf="$scope.rmCollapsed" class="dots">... </span>
            <span (click)="$scope.rmCollapsed = !$scope.rmCollapsed"
                [class.less]="!$scope.rmCollapsed"
                class="more-link">{{$scope.rmCollapsed && $scope.rmMoreTxt || $scope.rmLessTxt}}</span>
        </span>
        </span>`
})

export class ReadMoreComponent implements OnInit {
    @Input() description: string;

    $scope: ScopeModel = {};

    constructor() { }

    ngOnInit() {
        const html = this.description;

        this.$scope.rmLimit = this.$scope.rmLimit || 140;
        this.$scope.rmNeeded = html.length > this.$scope.rmLimit;

        this.$scope = {
            rmLimit: 140,
            rmNeeded: html.length > this.$scope.rmLimit,
            readMore: '',
            rmMoreTxt: 'more...',
            rmLessTxt: ' less',
            rmCollapsed: true,
            rmHtml: html,
            rmText: this.$scope.rmNeeded && html.substring(0, this.$scope.rmLimit) || ''
        };

    }
}
