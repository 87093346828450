import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '../window-factory';
import { CFG } from '../config';

@Injectable()
export class TokenService {

    private ls: any;
    private _token: string;

    constructor(@Inject(WINDOW) private window: Window) {
        this.ls = this.window.localStorage;
    }

    get token(): string {
        return this._token || this.ls.idash_token;
    }

    set token(token: string) {
        this.ls.idash_token = token;
    }

    destroyToken() {
        this.ls.removeItem('idash_token');
        this.ls.removeItem(CFG.idle.storeKey);
        this._token = null;
    }
}
