import {Component, OnInit, ChangeDetectorRef, ViewChild, Inject, ElementRef, NgZone} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {FormGroup} from '@angular/forms';
import {CFG, UtilService, ApiService, BranchModel} from '../core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {form1Fields, form2Fields, form3Fields, form4Fields, form5Fields, Files} from './add-property-form';
import {DatePipe} from '@angular/common';
import {forkJoin, pipe} from 'rxjs';
import {LandlordCrmEntry} from '../core/model/landlordCrmEntry.model';
import {map} from 'rxjs/operators';
import * as _ from 'lodash';
import {WINDOW} from '../core/window-factory';
import {MapsAPILoader} from '@agm/core';
import { Upload, UploadObj } from './file-upload.config';

declare var google;

@Component({
    selector: 'app-add-property',
    templateUrl: './add-property.component.html',
    styleUrls: ['./add-property.component.less', './styles.less']
})
export class AddPropertyComponent implements OnInit {
    @ViewChild('placesRef') placesRef: ElementRef;
    form1: FormGroup;
    form2: FormGroup;
    form3: FormGroup;
    form4: FormGroup;
    form5: FormGroup;
    CFG = CFG;
    billsIncluded = false;
    section = 1;
    date: Date = new Date;
    displayDatePicker = false;
    branch: BranchModel;
    landlordCrmEntries: LandlordCrmEntry[];
    branches: BranchModel[];
    showBranchField = false;
    success = null;
    error = null;
    isErrorMessage = false;
    upload = new UploadObj();
    U = Upload;

    constructor(
        private cd: ChangeDetectorRef,
        private utilService: UtilService,
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private datePipe: DatePipe,
        private apiService: ApiService,
        private ngbDateParserFormatter: NgbDateParserFormatter,
        @Inject(WINDOW) private window: Window
    ) {
    }

    ngOnInit() {
        this.form1 = new FormGroup(form1Fields);
        this.form2 = new FormGroup(form2Fields);
        this.form3 = new FormGroup(form3Fields);
        this.form4 = new FormGroup(form4Fields);
        this.form5 = new FormGroup(form5Fields);
        forkJoin(
            this.apiService.get('landlordcrmentries'),
            this.apiService.get('branches',
                {
                    offset: '0',
                    count: '999'
                }, {
                    ctrlType: 'branch',
                    identifier: CFG.customV2Identifier
                })
        ).pipe(
            map((data: [any, any]) => ({landlordCrmEntries: data[0].data, branches: data[1].data.Data}))
        )
            .subscribe((data: any) => {
                this.landlordCrmEntries = data.landlordCrmEntries;
                const relatedBranchIds = _.map(this.landlordCrmEntries, 'BranchID');
                this.branches = _.filter(data.branches, branch => relatedBranchIds.indexOf(branch.OID) >= 0);
                this.showBranchField = this.branches.length > 1;
                if (this.branches.length === 1) {
                    this.form1.get('BranchId').setValue(this.branches[0].OID);
                }
            });
        this.mapsAPILoader.load().then(() => {
            var map = new google.maps.Map(document.getElementById('map'), {
                center: {lat: -33.8688, lng: 151.219},
                zoom: 13
            });
            const autocompleteAddress1 = new google.maps.places.Autocomplete(
                this.placesRef.nativeElement,
                {
                    types: ['address']
                }
            );
            autocompleteAddress1.bindTo('bounds', map);
            autocompleteAddress1.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    let street_number = '';
                    const addressData = autocompleteAddress1.getPlace().address_components || [];
                    for (let i = 0; i < addressData.length; i++) {
                        if (addressData[i].types[0] === 'postal_code') {
                            this.form1.patchValue({Postcode: addressData[i].long_name});
                        } else {
                            this.form1.patchValue({Postcode: ''});
                        }

                        if (addressData[i].types[0] === 'route') {
                            this.form1.patchValue({Address1: addressData[i].long_name});
                        }

                        if (addressData[i].types[0] === 'administrative_area_level_2') {
                            this.form1.patchValue({Address2: addressData[i].long_name});
                        }
                        if (addressData[i].types[0] === 'locality' || addressData[i].types[0] === 'postal_town') {
                            this.form1.patchValue({Address3: addressData[i].long_name});
                        }

                        if (addressData[i].types[0] === 'street_number') {
                            street_number += addressData[i].long_name + ' ';
                        }

                        if (addressData[i].types[0] === 'route') {
                            street_number += addressData[i].long_name;
                        }
                    }
                    this.form1.patchValue({Address1: street_number});
                });
            });
        });


    }


    save(form: any): boolean {
        if (!form.valid) {
            this.utilService.markFormGroupDirty(form);
            return false;
        }

        return true;
    }

    goToNext(form: any) {
        this.window.scroll(0, 0);
        if (this.save(form)) {
            this.section += 1;
        }
        this.success = null;
        this.error = null;
    }

    goBack() {
        this.window.scroll(0, 0);
        this.section -= 1;
        this.success = null;
        this.error = null;
    }

    onSubmit() {
        if (!this.form1.valid || !this.form2.valid || !this.form3.valid || !this.form4.valid || !this.form5.valid) {
            this.utilService.markFormGroupDirty(this.form1);
            this.utilService.markFormGroupDirty(this.form2);
            this.utilService.markFormGroupDirty(this.form3);
            this.utilService.markFormGroupDirty(this.form4);
            this.utilService.markFormGroupDirty(this.form5);
            return;
        }

        const selectedBranchId = this.form1.value.BranchId;
        const selectedLandlord = _.find(this.landlordCrmEntries, {BranchID: selectedBranchId}).OID;

        const attachments = [];

        this.upload.getResult().forEach(res => {
            attachments.push(this.createAttachment(res.details, res.type, res.files));
        });

        const data = {
            ...this.buildPostBody(),
            ...{
                Attachments: attachments
            }
        };

        this.apiService.post(
            'createnewproperty',
            data,
            {
                branchID: selectedBranchId,
                landlordID: selectedLandlord
            }
        ).subscribe(
            resp => {
                this.success = `Thanks for providing your property details.
                    They have been submitted successfully and we will review the information in due course.`;
                this.isErrorMessage = false;
                this.resetForms();
            }, error => {
                this.error = 'Something went wrong, please try again later.';
                this.isErrorMessage = true;
            }
        );
    }

    handleFileInput($event, type: Upload) {
        const files = $event.target.files;

        this.upload.obj[type].files = [];

        if (files && files.length) {
            // Clear the error state before processing the new files
            this.upload.obj[type].errorFileSize = false;
            this.upload.obj[type].errorType = false;

            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader(),
                    file = files[i];

                reader.onload = () => {

                    if (this.upload.obj[type].maxFileSize < file.size) {
                        this.upload.obj[type].errorFileSize = true;
                    } else {
                        this.upload.obj[type].errorFileSize = false;
                        this.upload.obj[type].errorType = false;
                        this.upload.isAccepted({ type, reader, file });
                    }

                    // Need to run CD since file load runs outside of the zone
                    this.cd.markForCheck();
                };

                reader.readAsDataURL(file);
            }
        }
    }

    private createAttachment(details, type, files): any {
        const attachment = {
            Details: details,
            AttachmentType: type,
            Documents: []
        };

        files.forEach(f => {
            attachment.Documents.push({
                Name: f.name,
                Contents: f.base64,
                DocumentType: 'General',
                MimeType: f.mimeType,
                ExternalDocument: true,
                PublicLink: true
            });
        });
        return attachment;
    }

    private resetForms() {
        this.form1.reset();
        this.form2.reset();
        this.form3.reset();
        this.form4.reset();
        this.form5.reset();
        this.billsIncluded = false;
        this.upload = new UploadObj();
    }

    private buildPostBody() {
        let data = {};
        data = {
            ..._.pick(this.form1.value, ['Address1', 'Address2', 'Address3', 'Address4', 'Postcode']),
            ..._.pick(this.form2.value, ['NoOfBedrooms', 'NoOfBathrooms', 'ProperyType']),
        };
        const tmpInstructionTermStart: NgbDateStruct = this.form3.value.InstructionTermStart;
        // tslint:disable-next-line:max-line-length
        data['InstructionTermStart'] = new Date(Date.UTC(tmpInstructionTermStart.year, tmpInstructionTermStart.month, tmpInstructionTermStart.day)).toISOString();
        data['InstructionBondRequired'] = this.form3.value.Bond;
        data['AdvertisingRent'] = this.form3.value.AdvertisingRent;
        data['Description'] = this.form4.value.Description,
            data['RequestNotes'] = this.buildRequestNotes();
        return data;
    }

    private buildRequestNotes(): string {
        const notes = {
            ..._.pick(this.form3.value, ['water', 'gas', 'electricity', 'cleaning', 'internet', 'gardening']),
            ...this.form4.value,
            ...this.form5.value,
        };
        delete notes.Description;

        let RequestNotes = ``;
        Object.keys(notes).forEach(key => {
            RequestNotes += `${key}: ${notes[key]} \n`;
        });

        return RequestNotes;
    }
}
