import { Directive, OnInit, Input, Output, ElementRef, Inject, HostListener, EventEmitter } from '@angular/core';
import { ApiService } from '../services';
import { WINDOW } from '../window-factory';
import { saveAs } from 'file-saver';

declare const $: any;

@Directive({
    selector: '[appApiHref]'
})
export class ApiHrefDirective implements OnInit {
    @Input('appApiHref') apiHref: string;
    @Input() download: string;
    @Input() getUrlData: any;
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onEndDl = new EventEmitter<any>();

    blob;
    fileName;
    objectURL;
    canUseDownloadAttr;
    a;

    constructor(
        private $el: ElementRef,
        private apiService: ApiService,
        @Inject(WINDOW) private window: Window
    ) {
    }

    ngOnInit() {
        this.canUseDownloadAttr = ('download' in document.createElement('a'));

        // We get the url data from the `getUrlData` input.
        if (this.getUrlData) {
            this.blob = this.getUrlData['blob'] || this.getUrlData;
            this.fileName = this.download || this.getUrlData['fileName'] || '';

            if (this.canUseDownloadAttr) {
                this.a = this.window.document.createElement('a');
                this.window.document.body.appendChild(this.a);
                this.a.href = this.objectURL = this.window.URL.createObjectURL(this.blob);
                this.a.download = this.fileName;
            }
        } else {
            if (this.apiHref) {
                this.unbinds();
            }
        }
    }

    unbinds() {
        if (this.objectURL) {
            this.window.URL.revokeObjectURL(this.objectURL);
        }

        if (this.a) {
            this.window.document.body.removeChild(this.a);
        }

        this.getUrlData = this.blob = this.objectURL = null;
    }

    @HostListener('click', ['$event']) onClick() {
        // IE
        if (this.window.navigator.msSaveBlob) {

            $(this.$el).on('click', () => {
                this.window.navigator.msSaveBlob(this.blob, this.fileName);
                this.onEndDl.emit(true);
            });
        } else {
            // Call the download when Blob or ObjectURL exists, or the passed url is an ObjectURL (Blob URL).
            if (this.blob || this.objectURL || (this.apiHref && this.apiHref.indexOf('blob:') === 0)) {
                this.downloadBlob(this.blob || this.objectURL || this.apiHref, this.fileName);
            } else {
                // Get the URL content as blob, on success call download
                this.apiService.getBlob(this.apiHref, {}, {
                    type: 'application/pdf',
                    strictUrl: true,
                    createObjectUrl: !this.window.navigator.msSaveBlob,
                    parseFileName: !this.download,
                    runInBg: true
                }).subscribe((resp) => {
                    this.fileName = resp.fileName;
                    this.downloadPdf(resp.data, resp.fileName);
                });
            }
        }
    }

    downloadPdf(pdfUrl: string, pdfName: string) {
        saveAs(pdfUrl, pdfName);
        this.onEndDl.emit(true);
    }

    downloadBlob(blobOrObjectURL, fileName = this.download) {
        // IE
        if (this.window.navigator.msSaveBlob) {
            if (!this.blob) {
                this.blob = blobOrObjectURL;
            }

            this.window.navigator.msSaveBlob(this.blob, fileName);
        } else {
            if (this.getUrlData && this.canUseDownloadAttr) {
                this.a.click();
            } else {
                // Other browsers
                // For the first download we create temporary anchor element,
                // and fire the download with this element.
                // Meanwhile we set up the real anchor element too, so for the
                // next click the browser will download with the object url.
                let link = this.window.document.createElement('a');

                $(this.$el)
                    .off('click')
                    .prop('href', link.href = this.objectURL = blobOrObjectURL)
                    .prop('download', link.download = fileName);

                link.dispatchEvent(new MouseEvent('click'));
                setTimeout(() => {this.onEndDl.emit(true);}, 10);
                link = null;
            }
        }
        this.onEndDl.emit(true);
    }
}
