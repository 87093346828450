import {ColumnSetting} from '../core/shared/table-layout/layout.model';
import {PaginationSettings} from '../core/shared/table-layout/table.model';

export const maintenanceTable: ColumnSetting[] = [
    {
        primaryKey: 'Property',
        header: 'Property',
        headerClass: 'property'
    },
    {
        primaryKey: 'Description',
        header: 'Note',
        readMore: true,
        permissionName: 'enableMaintenanceNotesColumn'
    },
    {
        primaryKey: 'Reported',
        header: 'Reported',
        format: 'date'
    },
    {
        primaryKey: 'Status',
        header: 'Status'
    },
    {
        primaryKey: 'AssignedTo',
        header: 'Contractor'
    }
];
export const maintenancePagination: PaginationSettings = {
    id: 'p1',
    itemsPerPage: 15,
    maxSize: 9
};
