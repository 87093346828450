import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {UtilService} from './util.service';
import {map} from 'rxjs/operators';
import {WINDOW} from "../window-factory";

@Injectable({
    providedIn: 'root'
})
export class PropertyService {
    private ls: any;

    constructor(
        private apiService: ApiService,
        private utilService: UtilService,
        @Inject(WINDOW) private window: Window
    ) {
        this.ls = this.window.localStorage;
    }

    getPropertyPhotos(propertyId, params, reqCfg) {
        return this.apiService.get('property/' + propertyId + '/photos', params, reqCfg);
    }

    getTenancy(params, reqCfg) {
        return this.apiService.get('tenancy', params, reqCfg);
    }

    getSummaryOfExistingTenancies(params = {}, reqCfg = {}) {
        if (this.apiService.isCached('summaryOfExistingTenancies')) {
            return this.apiService.getCached('summaryOfExistingTenancies');
        } else {
            // Do not cache the summary response.
            reqCfg['cache'] = false;

            return this.apiService.get('summary', params, reqCfg)
                .pipe(
                    map((resp: ApiResponse) => {
                        // Filter out the closed and ended tenancies.
                        resp.data.Tenancies = resp.data.Tenancies.filter(item => {
                            const tenancyState = item.TenancyState.toLowerCase();

                            return !tenancyState.includes('closed tenancy') && !tenancyState.includes('ended tenancy');
                        });

                        // Cache the filtered summary response.
                        this.apiService.setCache('summaryOfExistingTenancies', resp);

                        return resp;
                    })
                );
        }
    }

    getSelfAssessment(params, reqCfg) {
        return this.apiService.get('/sas/files', params, reqCfg);
    }
}
