import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../services';
import { Settings } from '../model';

/**
 * This guard will check the user authentication and authorization for the activated route.
 */
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
    settings: Settings;
    loginPaths = ['/login', '/forgot-password', '/sign-up'];

    constructor(
        private router: Router,
        private authSvc: AuthService,
        private settingsSvc: SettingsService,
    ) {
        this.settings = this.settingsSvc.get();
    }

    /**
     * @inheritDoc
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (state.url === '/maintenance' && !this.settings.productSettings.enableMaintenanceSection) {
            return this.redirect('login');
        }

        return this.isAuthenticated(state.url);
    }

    /**
     * @inheritDoc
     */
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    isAuthenticated(path): Observable<boolean> | Promise<boolean> | boolean {
        return this.authSvc.isAuthenticated$
            .pipe(
                take(1),
                map((isAuthenticated: boolean) => {
                    if (
                        !isAuthenticated && this.loginPaths.indexOf(path) === -1
                    ) {
                        return this.redirect('login');
                    }

                    if (isAuthenticated && this.loginPaths.indexOf(path) >= 0) {
                        return this.redirect('dashboard');
                    }

                    if (isAuthenticated && path === '/') {
                        return this.redirect('dashboard');
                    }

                    return true;
                })
            );
    }

    private redirect(path) {
        this.router.navigate(['/' + path]);
        return false;
    }
}
